 
<div class="container-fluid  " id="pricelist">
  <br>
  <h2 class="  mb-3 fw-bold">Find The Perfect Suit </h2>
  <br>
  <div class=" ">
    <!-- Assist Card -->
    <div class="usecase-card mb-2"> 
      <div class="feature-card bg-white h-100"> 
          
        <h2 class="plan-name ">Orcaa Assist</h2>

        <div class="row">
          <div class="col-md-6"> 
            <div class="price mb-0">
              R284.99 <span class=" ">/month </span> 
            </div> 
            <b>3  seats </b>
          </div>
          

          <div class="col-md-6"> 
            <div class="price mb-0">
              R49.99 <span class=" ">/monthly</span> <br> 
            </div>  
            <b>add child per seat</b>  
            <br><br>
          </div> 
        </div> 
        <div class="row mb-4 mt-1"> 
          <div class="col-12">
            <a href="https://demo.orcaa.ai/franchise_registration" target="_blank" class="btn btn-purple w-100">Start Trial</a>
          </div> 
        </div>
      
        <ul class="feature-list text-start">
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                    Real-Time Academic Updates
                    </div>
                </div>
                <div class="feature-description">
                  <p> 
                  Parents receive instant updates on their children’s academic progress and achievements, helping them stay informed. 
                  </p>
              </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                    Integrated Behavioural Insights
                    </div>
                </div>
                <div class="feature-description">
                Track behavioural patterns to provide holistic support for student development.
              </div>
            </li>  

            <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                  Scheduled Progress Report                     
                  </div>
              </div>
              <div class="feature-description">
                <p> 
                Parents can set up recurring course progress reports to stay updated on their learner’s growth.                     
              </p>
            </div>
          </li>
          <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                  Parent-Purchased Intervention Courses
                  </div>
              </div>
              <div class="feature-description">
              Parents can purchase extra courses as part of intervention support, allowing tailored academic assistance.
            </div>
          </li> 

        </ul> 
      </div>
    </div> 

      <!-- Intership Card -->
      <div class="usecase-card mb-2"> 
        <div class="feature-card bg-white"> 
            
          <h2 class="plan-name">Orcaa Internship</h2>
  
          <div class="row">
            <div class="col-md-6"> 
              <div class="price mb-0">
                <span class=" "> Receive</span>  R99.99<span class=" "> </span> 
              </div> 
              per learner
              <b> <br> Mentors</b>
              <br> <br> 
            </div>
            
  
            <div class="col-md-6"> 
              <div class="price mb-0">
                R249.99<span class=" "> monthly</span> <br> 
              </div>  
              <b>External <br>Learners</b>  
              <br><br>
            </div> 
          </div>
  
          <div class="row"> 
            <div class="col-md-6">
              <div class="price mb-0">
                R249.99 <span class=" "> /monthly</span> <br> 
              </div>  
              per student 
              <b> <br>Small Skill <br >Trainers</b>
              <br> <br>
            </div>
  
            <div class="col-md-6"> 
              <div class="price mb-0">
                R249.99<span class=" "> /annually</span> <br> 
              </div>  
              per trainee
              <b> <br>Small to Medium Enterprises</b>
              <br> <br>
            </div>
          </div>
  
          <div class="row mb-4 mt-1"> 
            <div class="col-12">
              <a   target="_blank" class="btn btn-purple w-100">Start Trial</a>
            </div> 
          </div> 
          <br>
  
          <h2 class="plan-name"> Big and Large Corporates </h2>  
          
  
          <div class="row mb-4 mt-1"> 
            <div class="col-12">
              <a  target="_blank" class="btn btn-purple w-100 bg-transparent text-dark">Contact Us</a>
            </div> 
          </div>
        
          <ul class="feature-list text-start">
              <li class="feature-item">
                  <div class="feature-header">
                      <i class='bx bx-chevron-right'></i>
                      <div class="feature-text">
                        Advertise Internship Opportunities
                      </div>
                  </div>
                  <div class="feature-description">
                    <p> 
                      Promote internships effectively across channels.
                    </p>
                </div>
              </li>
              <li class="feature-item">
                  <div class="feature-header">
                      <i class='bx bx-chevron-right'></i>
                      <div class="feature-text">
                        Customisable Onboarding Workflow
                      </div>
                  </div>
                  <div class="feature-description">
                    Define processes to suit organisational needs.
                  </div>
              </li>  
  
              <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      AI-Powered Adaptive Assessments
                    </div>
                </div>
                <div class="feature-description">
                  <p> 
                    Assess intern skills accurately.
                  </p>
              </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Automated and Scheduled Assessments
                    </div>
                </div>
                <div class="feature-description">
                  Monitor intern progress over time.
                </div>
            </li> 
            <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    AI-Powered Marking and Feedback.
                  </div>
              </div>
              <div class="feature-description">
                Provide efficient grading and constructive feedback.
              </div>
            </li> 
            <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Generate Reporting on Defined Skills
                  </div>
              </div>
              <div class="feature-description">
                Keep track of intern development.
              </div>
            </li> 
  
          </ul> 
        </div>
  
  
      </div> 

    <!-- Plus Card -->
    <div class="usecase-card mb-2"> 
      <div class="feature-card bg-white"> 
          
        <h2 class="plan-name">Orcaa Plus</h2>
      
        <div class="price">
            P.O.A<span class="d-none"> ZAR per user/month</span>
        </div> 
        <div class="row mb-4 mt-1"> 
          <div class="col-12">
            <a  href="https://demo.orcaa.ai/franchise_registration" target="_blank" class="btn btn-purple w-100">Start Trial</a>
          </div> 
        </div>
      
        <ul class="feature-list text-start">
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Define Learning Skills
                    </div>
                </div>
                <div class="feature-description">
                  <p> 
                    Identify and outline specific skills required for educational programs.
                  </p>
              </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Customisable Skills Auditing Workflow
                    </div>
                </div>
                <div class="feature-description">
                  Create workflows that align with institutional requirements.
                </div>
            </li>  

            <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Audit of Accredited Courses                    
                  </div>
              </div>
              <div class="feature-description">
                <p> 
                  Evaluate and verify course quality and accreditation.
                </p>
            </div>
          </li>
          <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Plagiarism Checker
                  </div>
              </div>
              <div class="feature-description">
                Ensure originality in student submissions.
              </div>
          </li> 
          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Skills-Audited Certificates                  
                </div>
            </div>
            <div class="feature-description">
              Issue certificates validating student achievements.
            </div>
          </li> 

        </ul> 
      </div>
    </div>  
    

     <!-- Corporate Card -->
    <div class="usecase-card mb-2" > 
      <div class="feature-card bg-white "> 
          
        <h2 class="plan-name">Orcaa Corporate</h2>
      
        <div class="price">
            P.O.A<span class="d-none"> ZAR per user/month</span>
        </div> 
        <div class="row mb-4 mt-1"> 
          <div class="col-12">
            <a  href="https://demo.orcaa.ai/franchise_registration" target="_blank" class="btn btn-purple w-100">Start Trial</a>
          </div>
            
        </div>
      
        <ul class="feature-list text-start">
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Define Employee Training Skills
                    </div>
                </div>
                <div class="feature-description">
                  <p> 
                    Identify and outline specific skills required for employee development programs.
                  </p>
              </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Link Training to Company KPIs
                    </div>
                </div>
                <div class="feature-description">
                  Align training programs with key performance indicators.
                </div>
            </li>  

            <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    AI-Powered Adaptive Assessments                    
                  </div>
              </div>
              <div class="feature-description">
                <p> 
                  Implement intelligent assessments that adapt to employee progress.
                </p>
              </div>
            </li>
            <li class="feature-item">
                <div class="feature-header">
                    <i class='bx bx-chevron-right'></i>
                    <div class="feature-text">
                      Customisable Skills Auditing Workflow
                    </div>
                </div>
                <div class="feature-description">
                  Tailor workflows to align skills auditing with your company’s strategic goals.
                </div>
            </li> 

          <li class="feature-item">
            <div class="feature-header">
                <i class='bx bx-chevron-right'></i>
                <div class="feature-text">
                  Host Paid or Free Training Modules
                </div>
            </div>
            <div class="feature-description">
              Provide flexibility in offering training content to meet diverse employee development needs.
            </div>
          </li> 
          <li class="feature-item">
              <div class="feature-header">
                  <i class='bx bx-chevron-right'></i>
                  <div class="feature-text">
                    Automated and Scheduled Assessments
                  </div>
              </div>
              <div class="feature-description">
                Set up assessments that adapt to each employee’s progress.
              </div>
          </li> 

        </ul> 
      </div>
    </div>

  

  </div>
  <br><br>

</div> 