<div class="  my-5 custom-background" id="usecase-setion">
  <h2 class="text-center used-cases-title mb-4 text-dark">Use Cases</h2>

  <div class="usecase-scroll">

    <!-- Orcaa Assist usecase Card 4 -->
    <div class="usecase-card h-100">
      <div class="  border-0">
          <div class="usecase-image">
              <img src="@/assets/images/usecase-assist-img.png" class="img-fluid rounded-5">
          </div>
          <div class="card-body px-0">
              <h5 class="card-title mb-2">Orcaa Assist</h5>
              <p class="card-text text-muted"> 
                Designed for parents, tutors, and schools needing real-time student insights, interactive learning tools, and personalized feedback to keep students engaged, motivated, and on track academically.
              </p>
               
              <div class="d-flex justify-content-between align-items-center">
                  
                  <button class="add-to-cart"  @click="scroll_to_sector_sec"> View More</button>
              </div>
          </div>
      </div>
    </div>
    
    <!-- Orcaa Internships usecase Card 2 -->
    <div class="usecase-card h-100">
        <div class="  border-0">
            <div class="usecase-image">
                <img src="@/assets/images/usecase-internship-img.png" class="img-fluid">
            </div>
            <div class="card-body px-0">
                <h5 class="card-title mb-2">Orcaa Internships</h5>
                <p class="card-text text-muted">
                  Beneficial for internships, as it monitors intern progress to ensure they 
                  are gaining the necessary experience and skills, ultimately determining if they are employable at the end of their internship.
                </p>
                 
                <div class="d-flex justify-content-between align-items-center">
                    
                    <button class="add-to-cart"  @click="scroll_to_sector_sec"> View More</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Orcaa Plus usecase Card 3 -->
    <div class="usecase-card h-100">
        <div class="  border-0">
            <div class="usecase-image">
                <img src="@/assets/images/usecase-plus-img.png" class="img-fluid">
            </div>
            <div class="card-body px-0">
                <h5 class="card-title mb-2">Orcaa Plus</h5>
                <p class="card-text text-muted">
                  Created for learner evaluation, tracking & reporting. It supports students, admins, and 
                educators in private schools, universities, and homeschooling by augmenting existing educational systems
                 </p>
                 
                <div class="d-flex justify-content-between align-items-center">
                    
                    <button class="add-to-cart"  @click="scroll_to_sector_sec"> View More</button>
                </div>
            </div>
        </div>
    </div> 
    

     <!-- Orcaa X usecase Card 1 -->
     <div class="usecase-card h-100">
        <div class="  border-0">
            <div class="usecase-image">
                <img src="@/assets/images/usecase-corp-img.png" class="img-fluid">
            </div>
            <div class="card-body px-0">
                <h5 class="card-title mb-2">Orcaa X</h5>
                <p class="card-text text-muted"> 
                  Enhancing corporate education by integrating with existing systems, it uses workplace content to support 
                  training and promote lifelong learning, minimizing the need for new resources.
                </p>
                
                <div class="d-flex justify-content-between align-items-center">
                    
                    <button class="add-to-cart" @click="scroll_to_sector_sec"> View More</button>
                </div>
            </div>
        </div>
    </div>
  </div>
  
  <!-- Title Added Here -->
  <div class="row justify-content-center align-items-center d-none">
    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
      <div class="card custom-card">
        <img src="@/assets/images/Corporate-about-picture.png" class="card-img-top custom-card-img" alt="Card image">
        <div class="card-body">
          <div class="custom-logo-container">
            <img class="about-logo" src="@/assets/images/Orcaa.ai_Corporate.svg" alt="Orcaa logo">
            <p class="custom-logo-text mt-2">Orcaa<b>Corporate</b></p>
          </div>
           <p class="mt-1 text-start content-text">Enhancing corporate education by integrating with existing systems, it uses workplace content to support training and promote lifelong learning, minimizing the need for new resources.</p>
          <a href="#" class="btn btn-primary">View more</a>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
      <div class="card custom-card">
        <img src="@/assets/images/Internship-about-picture.png" class="card-img-top custom-card-img" alt="Card image">
        <div class="card-body">
           <div class="custom-logo-container">
            <img class="about-logo" src="@/assets/images/Orcaa.ai_Internship.svg" alt="Orcaa logo">
            <p class="custom-logo-text mt-2">Orcaa<b>Internships</b></p>
          </div>

          <p class="mt-1 text-start content-text">Beneficial for internships, as it monitors intern progress to ensure they 
            are gaining the necessary experience and skills, ultimately determining if they are employable at the end of their internship.</p>
          <a href="#" class="btn btn-primary">View more</a>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
      <div class="card custom-card">
        <img src="@/assets/images/Plus-about-picture.png" class="card-img-top custom-card-img" alt="Card image">
        <div class="card-body">
           <div class="custom-logo-container">
            <img class="about-logo" src="@/assets/images/Orcaa.ai_Plus.svg" alt="Orcaa logo">
            <p class="custom-logo-text mt-2">Orcaa<b>Plus</b></p>
          </div>
           <p class="mt-1 text-start content-text">Created for learner evaluation, tracking & reporting. It supports students, admins, and 
            educators in private schools, universities, and homeschooling by augmenting existing educational systems.</p>

          <a href="#" class="btn btn-primary">View more</a>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
      <div class="card custom-card">
        <img src="@/assets/images/Assist-about-picture.png" class="card-img-top custom-card-img" alt="Card image">
        <div class="card-body">
          <div class="custom-logo-container">
            <img class="about-logo" src="@/assets/images/Orcaa.ai_Assist.svg" alt="Orcaa logo">
            <p class="custom-logo-text mt-2">Orcaa<b>Assist</b></p>
          </div>

           <p class="mt-1 text-start content-text">OrcaaAssist is designed for parents, tutors, schools, and mentors who 
            need real-time insights into student performance. The platform provides timely academic updates, interactive learning, and personalized feedback to ensure students stay on track.</p>
          <a href="#" class="btn btn-primary">View more</a>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
      <div class="card custom-card">
        <img src="@/assets/images/Recruit-about-picture.png" class="card-img-top custom-card-img" alt="Card image">
        <div class="card-body">
          <div class="custom-logo-container">
            <img class="about-logo" src="@/assets/images/Orcaa.ai_Recruit.svg" alt="Orcaa logo">
            <p class="custom-logo-text mt-2">Orcaa<b>Recruit</b></p>
          </div>
           <p class="mt-1 text-start content-text">Built for Talent Managers, OrcaaRecruit automates the entire hiring process, candidate shortlisting 
            & interview scheduling, while providing real-time insights into candidate skills, matching the right candidate with the right role efficiently.</p>

          <a href="#" class="btn btn-primary">View more</a>
        </div>
      </div>
    </div>
  </div>
</div>
